import React from "react"
import FeatureItem from "../components/FeatureItem"
import { useStaticQuery, graphql } from "gatsby"

function Appartements() {
  const { allServicesYaml } = useStaticQuery(graphql`
    {
      allServicesYaml {
        edges {
          node {
            title
            description
            link
            image {
              childImageSharp {
                resize(width: 300, height:300) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div id = "services" className="container-fluid" style={{ backgroundColor: "#FFCD6C" }}>
      <div className="container">
        <div className="flexbox">
          <h1 className="text-center my-5">Unsere Ferienwohnungen</h1>
          <p>Wenn Sie die Salzburger Alpenwelt besuchen möchten, dann bieten wir Ihnen eine <b>gemütliche Unterkunft mit familiärer Atmosphäre.</b></p>
          <p>
            Unser Haus liegt eingebettet in die bestechend schöne Bergwelt des Tennengebirges und ist deshalb idealer Ausgangspunkt für zahlreiche <b>sportliche Aktivitäten im Sommer wie auch im Winter</b>.
            In zehn Gehminuten erreichen unsere Urlaubsgäste den Ort, Badesee, Spielplatz sowie Tennisplätze. Im Winter steigen Sie in <b>50 m Entfernung in die Loipe oder den Nulltarif-Skibus ein</b>.
          </p>
          <p>Unser Appartementhaus liegt in <b>ruhiger und sonniger Lage</b> von Werfenweng und bietet eine herrliche Aussicht auf die umliegende Bergwelt.
            Unser Haus ist von einem schönen Garten umgeben. Unsere Gäste können unseren Garten im Sommer für eine <b>Grillerei 
            nutzen</b>. Auch im Winter kann unser Garten für ein Zusammensein bei Glühwein genutzt werden.
          </p>
          <div className="row">
            {allServicesYaml.edges.map(({ node }) => {
              return <FeatureItem key={node.title} title={node.title} src={node.image.childImageSharp.resize.src} description={node.description} link={node.link} round={true}/>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Appartements
