import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export const StyledAnchorLink = styled(AnchorLink)`
  width: 250px;
  padding: 10px;
  border: none;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  background: #FFA800;
  border-radius: 30px;
  text-transform: uppercase;
  text-decoration: none;
  text-align:center;
  color : white;
  font-weight : 600;
  margin-top : 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #d88e00;
  }
`;

export const StyledLink = styled.a`
  width: 250px;
  padding: 10px;
  border: none;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  background: #FFA800;
  border-radius: 30px;
  text-transform: uppercase;
  text-decoration: none;
  text-align:center;
  color : white;
  font-weight : 600;
  margin-top : 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #d88e00;
    color : white;
  }
`;