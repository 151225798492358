import React from "react"
import FeatureItem from "../components/FeatureItem"
import { useStaticQuery, graphql } from "gatsby"

function Features() {
  const { allFeaturesYaml } = useStaticQuery(graphql`
    {
      allFeaturesYaml {
        edges {
          node {
            title
            description
            link
            image {
              childImageSharp {
                resize(width: 300, height:300) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div id = "team" className="container-fluid" style={{ backgroundColor: "#AFECA4" }}>
      <div className="container">
        <div className="flexbox">
          <h1 className="text-center my-4">Besonderheiten</h1>
          <p>Wer sich für einen Urlaub im schönen Werfenweng und Umgebung entscheidet, der sollte sein Auto stehen lassen und die Alpenlandschaft auf sanfte Weise erkunden. Die Entschleunigung ermöglicht, die Landschaft mit neuen Augen zu sehen und auf fast 1.000 Meter Seehöhe Dinge zu entdecken, die man beim schnellen Vorbeifahren nicht bemerkt.</p>
          <div className="row">
            {allFeaturesYaml.edges.map(({ node }) => {
              return <FeatureItem key={node.title} title={node.title} src={node.image.childImageSharp.resize.src} description={node.description} size={12 / allFeaturesYaml.edges.length} link={node.link}/>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
