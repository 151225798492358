import React from "react"
import Fade from "react-reveal/Fade"

function FeatureItem({ src, title, description, link, externalLink, size, round}) {
  const containerClass = `col-lg-${size ? size : 4} my-4 col-sm-12 text-center`;
  const imgClass =  round ? 'img-fluid rounded-circle' : 'img-fluid';
  return (
    <Fade bottom>
      <div className={containerClass}>
        {src && <img src={src} className={imgClass} alt={title} title={title} width="300" height="300" />}
        <h3 className="mt-5">{title}</h3>
        <p>{description}</p>
        {link && <a href={link}>Mehr Information</a>}
        {externalLink && <a href={externalLink} target="_blank" rel="noopener noreferrer">Mehr Information</a>}
      </div>
    </Fade>
  )
}

export default FeatureItem
