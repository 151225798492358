import React from "react"
import "../global.css"
import LandingPage from "../subpages/LandingPage";
import Appartements from "../subpages/Appartements";
import Features from "../subpages/Features";
import End from "../subpages/End";
import Footer from "../components/Footer";
import SeoHelmet from "../components/seo"

function Index() {
  return (
    <div>
      <SeoHelmet title="Ferienwohnungen Werfenweng - Haus Gruber Ferienwohnungen" description="Im Haus Gruber Ferienwohnungen können Sie in einer gemütlich und modern ausgestatteten Ferienwohnung Ihren Traumurlaub in den Bergen Österreichs verbringen." />
      <LandingPage/>
      <Appartements/>
      <Features/>
      <End/>
      <Footer/>
    </div>
  )
}

export default Index
